import {_} from 'lib';

import whoWeAre from './who-we-are';
import aCompleteSystem from './a-complete-system';
import functionalInnovation from './functional-innovation';
import premiumQuality from './premium-quality';
import ourProcess from './our-process';
import designEngine from './design-engine';
import budgetGuide from './budget-guide';
import designGuide from './design-guide';
import pricingWithExamples from './pricing-with-examples';
import flexibleOrganization from './flexible-organization';
import backsplashCountersSinks from './backsplash-counters-sinks';
import materialsAndFinishes from './materials-and-finishes';
import pulls from './pulls';
import basicComponents from './storage-components';
import projectStories from './project-stories';
import commonlyAskedQuestions from './commonly-asked-questions';
import appliancesFixtures from './appliances-fixtures';
import ordersPayments from './orders-payments';
import warrantyCare from './warranty-care';
import careers from './careers';
import awards from './awards';
import press from './press';
import daylight from './daylight';
import workplace from './workplace';
import portfolio from './portfolio';
import subscribe from './subscribe';

//project stories
import additionBySubtraction from './addition-by-subtraction';
import aModernInterpretation from './a-modern-interpretation';
import understory from './understory';
import findingGoldilocks from './finding-goldilocks';
import strippedDownSuburban from './stripped-down-suburban';
import miseEnScene from './mise-en-scene';
import creativeAssets from './creative-assets';
import kindredSpirits from './kindred-spirits';
import kitchenChameleon from './kitchen-chameleon';
import theNonconformist from './the-nonconformist';
import trueToForm from './true-to-form';
import modernHistory from './modern-history';
import biCoastal from './bi-coastal';
import fitForARestaurateur from './fit-for-a-restaurateur';
import aNaturalSystem from './a-natural-system';

//new and news
import lifelongProductSimpleFixes from './lifelong-product-simple-fixes';
import newDrawerComponents from './new-drawer-components';
import newYorkAnnouncement from './new-york-announcement';
import allAboutFlow from './all-about-flow';
import onLocation from './on-location';
import whiteOut from './white-out';
import inc5000Award from './inc-5000-award';

import visitAShowroom from './visit-a-showroom';
import losAngeles from './los-angeles';
import newYork from './new-york';
import seattle from './seattle';
import millValley from './mill-valley';

//unlinked project photo collections
import anArchitectsIslandOffice from './an-architects-island-office';
import prettyInPink from './pretty-in-pink';
import sanFranciscoMinimalist from './san-francisco-minimalist';
import naturesTouch from './natures-touch';

//HINT pages that do not appear in the menu but are still accessible
var unlistedPages = [
  creativeAssets,
  miseEnScene,
  strippedDownSuburban,
  findingGoldilocks,
  understory,
  aModernInterpretation,
  additionBySubtraction,
  kindredSpirits,
  kitchenChameleon,
  theNonconformist,
  trueToForm,
  modernHistory,
  biCoastal,
  newYorkAnnouncement,
  allAboutFlow,
  seattle,
  millValley,
  losAngeles,
  newYork,
  lifelongProductSimpleFixes,
  anArchitectsIslandOffice,
  prettyInPink,
  sanFranciscoMinimalist,
  naturesTouch,
  fitForARestaurateur,
  aNaturalSystem
];

var tree = [
  {title: 'Home', key: 'landing-page'},
  {spaceBefore: true, ...whoWeAre},
  {title: 'Why Space Theory', children: [
    aCompleteSystem,
    functionalInnovation,
    premiumQuality,
  ]},
  {title: 'How We Work', children: [
    ourProcess,
    designEngine,
    workplace,
  ]},
  {title: 'Product Information', children: [
    basicComponents,
    flexibleOrganization,
    backsplashCountersSinks,
    pulls,
    materialsAndFinishes,
    newDrawerComponents,
    daylight,
  ]},
  {title: 'Pricing', children: [
    pricingWithExamples,
    budgetGuide,
    // {
    //   url: 'https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-pricelist.pdf',
    //   title: 'Pricelist'
    // }
  ]},
  {title: 'Planning Resources', children: [
    designGuide,
    appliancesFixtures,
	  ordersPayments,
	  warrantyCare,
    commonlyAskedQuestions,
  ]},
  {spaceBefore: true, ...projectStories},
  portfolio,
  awards,
  press,
  {title: 'The Latest', children: [
    inc5000Award,
    onLocation,
    whiteOut
  ]},
  // {spaceBefore: true, title: 'Trade Site', url: 'https://opendoor.henrybuilt.com'},
  {spaceBefore: true, key: 'contact', title: 'Get in Touch', isStatic: true},
  careers,
  subscribe,
  visitAShowroom,
  ..._.map(unlistedPages, (page) => ({...page, isUnlisted: true}))
];

var flatPages = _.flatMapDeep(tree, page => [page, ...page.children || []]);

_.forEach(flatPages, page => page.title = (page.title || _.startCase(page.key || '')));

var index = _.keyBy(_.filter(flatPages, item => item.key !== undefined), 'key');

//HINT use to generate sitemap.xml
// var sitemapContents = _.chain(flatPages)
//   .filter(page => page.key !== undefined)
//   .map(page => {
//     var lastModified = page.lastModified || new Date().toISOString().split('T')[0];

//     var computedPriority = _.round(((flatPages.length - _.indexOf(flatPages, page)) / flatPages.length), 1);
//     computedPriority = computedPriority >= 0.1 ? computedPriority : 0.1;

//     var priority = page.priority || computedPriority;

//     return `<url>
//   <loc>https://spacetheory.com/page/${page.key}</loc>
//   <changefreq>monthly</changefreq>
//   <lastmod>${lastModified}</lastmod>
//   <priority>${priority}</priority>
// </url>`;
//   })
//   .join('\n')
//   .value();

// console.log(sitemapContents);

export default {tree, index};
